import { defineStore } from 'pinia';
import type { IAPIFeatureFlag } from '@hokify/feature-flag-interface';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';

interface IFeatureFlagState {
	featureFlags: IAPIFeatureFlag[];
}

export const useFeatureFlagStore = defineStore('featureFlag', {
	state: (): IFeatureFlagState => ({
		featureFlags: []
	}),
	actions: {
		async featureFlagEnabledByName(name: string): Promise<boolean> {
			if (!this.featureFlags.length) {
				await this.getAllFeatureFlags();
			}

			const featureFlag = this.featureFlags.find(flag => flag.name === name);
			const featureFlagGlobalEnabled = featureFlag?.enabled ?? false;

			const localStorageValue = lsTest() && localStorage.getItem(name);
			const isSetInLocalStorage = localStorageValue !== null;

			const enabled = isSetInLocalStorage ? localStorageValue === 'true' : featureFlagGlobalEnabled;

			return enabled;
		},
		async getAllFeatureFlags(): Promise<IAPIFeatureFlag[]> {
			if (this.featureFlags.length) {
				return this.featureFlags;
			}
			try {
				const result = await this.$nuxt.$hokFetch(`/app-api/feature-flags`);

				this.featureFlags = result;

				return result;
			} catch (error) {
				console.error('Error fetching feature flags', error);

				return [];
			}
		},
		async getFeatureFlagEnabledByName(name: string): Promise<boolean> {
			try {
				const result = this.$nuxt.$hokFetch(`/app-api/feature-flags/${name}`);

				return result;
			} catch (error) {
				console.error('Error fetching feature flag', error);
				return false;
			}
		}
	}
});
